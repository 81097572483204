import React from 'react';
import useLanguage from '../../Hooks/useLanguage';

const FaqPage = ({ dataFaq }) => {
  const langtoggle = useLanguage;
  const [activeItemID, setActiveItemID] = React.useState(0);

  return (
    <div className="faq">
      <section className="faq-section">
        {dataFaq &&
          dataFaq.map((item, index) => {
            if(item.title_ua || item.title_ru || item.title_en) {
              return (
                  <h2 className="faq-title" key={index}>
                    {langtoggle(item.title_ua,item.title_ru,item.title_en)}
                  </h2>
              )
            } else if(item.faq) {
              return (
                <div className="accordion">
                  {
                      item.faq.map((answer, index) => {
                      return (
                        <div className="accordion-item" key={index} id={answer.id}>
                          <div
                            role="presentation"
                            className={
                              activeItemID === answer.id
                                ? 'question is-active'
                                : 'question'
                            }
                            onClick={() =>
                              activeItemID === answer.id
                                ? setActiveItemID(0)
                                : setActiveItemID(answer.id)
                            }
                          >
                            <h3 className="question-title">
                              {langtoggle(
                                answer.title_ua,
                                answer.title_ru,
                                answer.title_en
                              )}
                            </h3>
                            <i className="accordion-icon">
                              <span className="icon-line icon-line__1"></span>
                              <span className="icon-line icon-line__2"></span>
                            </i>
                          </div>
                          <div
                            className={
                              activeItemID === answer.id
                                ? 'answer is-active'
                                : 'answer'
                            }
                          >
                            <div className="answer-wrapper">
                              <p className="answer-title">
                                {langtoggle(
                                  answer.content_ua,
                                  answer.content_ru,
                                  answer.content_en
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
               )
            }
             else {
              return null;
            }
          })}
      </section>
    </div>
  );
};

export default FaqPage;
