import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import MainScreenSecond from '../components/UI/MainScreenSecond/MainScreenSecond';
import Instagram from '../components/Layout/Instagram/Instagram';

import dataFaq from '../db/dataFaq';
import FaqPage from '../components/Pages/FaqPage/FaqPage';

const Faq = ({data}) => {
  const { t } = useTranslation();

  const faqData = dataFaq;

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Відповіді на Запитання',
      title_ru: 'Ответы на Вопросы',
      title_en: 'Answers on Questions',
      to: '/faq/',
    },
  ];

  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('Home')} />
      <MainScreenSecond
        mainData={data.strapiFaq}
        crumbsNav={crumbsNav}
      />
      <FaqPage faqData={faqData} dataFaq={data.strapiFaq.faq_title} />

      <Instagram />
    </Layout>
  );
};

export default Faq;

export const query = graphql`
query FaqPage($language: String!) {
  strapiFaq {
    title_ua
    title_ru
    title_en
    image {
      localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    faq_title
  }

  locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`