const dataFaq = [
  {
    id: 1,
    title_ua: 'Основні Питання',
    title_ru: 'Основные Вопросы',
    title_en: 'Main Questions',
    questions: [
      {
        id: 'q01',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q02',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q03',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q04',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q05',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
    ],
  },
  {
    id: 2,
    title_ua: 'Медичні Питання',
    title_ru: 'Медицинские Вопросы',
    title_en: 'Medical Questions',
    questions: [
      {
        id: 'q06',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q07',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q08',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q09',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
    ],
  },
  {
    id: 3,
    title_ua: 'Медичні Питання',
    title_ru: 'Медицинские Вопросы',
    title_en: 'Medical Questions',
    questions: [
      {
        id: 'q10',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q11',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q12',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q13',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
      {
        id: 'q14',
        question_ua: 'З якого віку можна відвідувати клініку?',
        question_ru: 'С какого возраста можно посещать клинику?',
        question_en: 'At what age can you visit the clinic?',
        answer_ua:
          'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
        answer_ru:
          'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
        answer_en:
          'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
      },
    ],
  },
];

export default dataFaq;
